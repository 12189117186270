import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AddSectionDto, EditResourceDto, FileResponseDto, ResourceDto, SectionBaseDto } from '../../../models/dtos';
import moment from 'moment';
import { ViewState } from '../../../models/app';
import Swal from 'sweetalert2'
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: []
})
export class ResourceComponent implements OnInit {
  constructor(private http: HttpClient, private route: ActivatedRoute, private titleService: Title, private _router: Router) { }

  public id?: string | null;
  public resource: ResourceDto | null = null
  public sections: SectionBaseDto[] | null = null
  moment: any = moment;
  newSection: AddSectionDto = new AddSectionDto();

  //file upload
  @ViewChild('resourceImage') resourceImageElement?: ElementRef;
  fileToUpload: File | null = null;

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  infoViewState = ViewState.loading;
  sectionsViewState = ViewState.loading;
  addSectionViewState = ViewState.content;

  //modals
  @ViewChild('addSectionModal') public addSectionModal?: ModalDirective;

  ngOnInit() {
    //update title
    this.titleService.setTitle('Resource | Trucking Standards Admin');

    //parse query params
    this.id = this.route.snapshot.paramMap.get('id');

    //get resource and data
    this.getResource(this.id);
    this.getSections(this.id);

    //register for route changes
    this.route.params.subscribe((params) => {
      this.id = this.route.snapshot.paramMap.get('id');

      //get resource and data
      this.resource = null;
      this.sections = null;
      this.getResource(this.id);
      this.getSections(this.id);
    });
  }

  //data
  getResource(id: string | null) {
    this.viewState = ViewState.loading;

    this.http
      .get<ResourceDto>(`${environment.services_legacy_management}/v1/resources/${id}`)
      .subscribe(result => {
        this.resource = result;
        this.viewState = ViewState.content;
        this.infoViewState = ViewState.content;
      });
  }

  getSections(id: string | null) {
    this.sectionsViewState = ViewState.loading;

    this.http
      .get<SectionBaseDto[]>(`${environment.services_legacy_management}/v1/resources/${id}/sections`)
      .subscribe(result => {
        this.sections = result;
        this.sectionsViewState = ViewState.content;
      });
  }

  saveResource() {
    this.infoViewState = ViewState.loading;
    const editDto = this.editDtoFromResource(this.resource);

    this.http
      .put<ResourceDto>(`${environment.services_legacy_management}/v1/resources/${this.id}`, editDto)
      .subscribe(resource => {
        this.resource = resource;
        this.infoViewState = ViewState.content;
      });
  }

  deleteResource() {
    this.viewState = ViewState.loading;

    this.http
      .delete(`${environment.services_legacy_management}/v1/resources/${this.id}`)
      .subscribe(() => {
        //redirect to resources
        this._router.navigateByUrl('/')
      }, (error) => {
        this.viewState = ViewState.content;
      });
  }

  addSection(newSection: AddSectionDto) {
    newSection.resourceId = Number(this.id);
    this.addSectionViewState = ViewState.loading;

    this.http
      .post<SectionBaseDto>(`${environment.services_legacy_management}/v1/sections`, newSection)
      .subscribe(section => {
        this.sections?.push(section);
        this.addSectionModal?.hide();
        this.addSectionViewState = ViewState.content;
      }, (error) => {

      });
  }

  uploadFile(file: File | null | undefined) {
    if (file) {
      //package form data
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      //upload
      this.infoViewState = ViewState.loading;
      return this.http
        .post<FileResponseDto>(`${environment.services_legacy_management}/v1/files-upload`, formData)
        .subscribe(response => {
          if(this.resource) {
            this.resource.coverImageUrl = response.src;
            this.saveResource();
          }
        }, (error) => {
          this.infoViewState = ViewState.content;
        });
    } else {
      return;
    }
  }

  //transforms
  editDtoFromResource(resource: ResourceDto | null): EditResourceDto {
    const dto = new EditResourceDto();
    dto.coverImageUrl = resource?.coverImageUrl;
    dto.description = resource?.description;
    dto.free = resource?.free;
    dto.published = resource?.published;
    dto.title = resource?.title;

    return dto;
  }

  //actions
  didClickUploadImage() {
    this.resourceImageElement?.nativeElement.click();
  }

  handleFileInput(e: Event) {
    this.uploadFile((<HTMLInputElement>e.target)?.files?.item(0));
  }

  //modal
  presentAddSection() {
    this.newSection = new AddSectionDto();
    this.addSectionModal?.show();
  }

  //alerts
  confirmDelete() {
    Swal.fire({
      title: "Confirm Delete Resource",
      text: "Are you sure you want to delete this resource and its children?",
      icon: 'warning',
      confirmButtonText: 'Delete',
      confirmButtonColor: "#ff5370",
      showCancelButton: true
    }).then((state) => {
      /* Read more about isConfirmed, isDenied below */
      if (state.value) {
        this.deleteResource();
      }
    });
  }
}
