import { Component, Input, OnInit } from '@angular/core';
import { ResourceDto } from '../../../models/dtos';
import moment from 'moment';

@Component({
  selector: 'app-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: []
})
export class ResourceCardComponent implements OnInit {
  //vars
  @Input() resource!: ResourceDto;
  moment: any = moment;

  
  constructor() { }

  ngOnInit() {
  }

  // formattedDate(date: Date) : string {
  //   return moment(resource.updatedTimestamp).format('ll')
  // }
}
