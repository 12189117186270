import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { ViewState } from '../../../models/app';
import { AddResourceDto, ResourceDto } from '../../../models/dtos';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: []
})
export class ResourcesComponent implements OnInit {

  constructor(private http: HttpClient, private titleService: Title) { }

  //vars
  public resources: ResourceDto[] = []
  newResource: AddResourceDto = new AddResourceDto();

  //view states
  viewStates = ViewState;
  viewState = ViewState.loading;
  addResourceViewState = ViewState.content;

  //modals
  @ViewChild('addResourceModal') public addResourceModal?: ModalDirective;

  ngOnInit() {
    this.titleService.setTitle('All Resources | Trucking Standards Admin');
    this.getResources();
  }

  //api
  getResources(): void {
    this.viewState = ViewState.loading;

    this.http
      .get<ResourceDto[]>(`${environment.services_legacy_management}/v1/resources`)
      .subscribe(result => {
        this.resources = result;
        this.viewState = ViewState.content;
      });
  }

  addResource(newResource: AddResourceDto) {
    this.addResourceViewState = ViewState.loading;

    this.http
      .post<ResourceDto>(`${environment.services_legacy_management}/v1/resources`, newResource)
      .subscribe(resource => {
        this.resources.push(resource);
        this.addResourceModal?.hide();
        this.addResourceViewState = ViewState.content;
      }, (error) => {

      });
  }

  //modal
  presentAddResource() {
    this.newResource = new AddResourceDto();
    this.addResourceModal?.show();
  }
}
