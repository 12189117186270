<div class="animated fadeIn">
  <!--loading-->
  <div *ngIf="viewState == viewStates.loading" class="text-center">
    <div class="spinner-border text-ts" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <c-card *ngIf="viewState == viewStates.content" class="mb-4 bg-transparent border-0">
    <c-card-body>
      <c-row>
        <c-col sm="7">
          <h3 class="card-title mb-0" id="traffic">
            <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded me-2"
              style="object-fit: contain; width: 30px; height: 30px;">
            <b>All Resources</b>
          </h3>
        </c-col>
        <c-col sm="5">
          <div style="text-align: right;">
            <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddResource()">
              <i class="fa fa-plus mr-2"></i> Add Resource
            </button>
          </div>
        </c-col>
      </c-row>

      <c-row class="text-center mt-4">
        <c-col xs="3" *ngFor="let resource of resources; let i = index">
          <div class="mb-2">
            <app-resource-card [resource]='resource'></app-resource-card>
          </div>
        </c-col>
      </c-row>
    </c-card-body>
  </c-card>
  <!-- 
  <div *ngIf="viewState == viewStates.content" class="card border-0 bg-transparent">
    <div class="card-header bg-transparent">
      <span class="h3">
        <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded mr-2"
          style="object-fit: contain; width: 30px; height: 30px;">
        <b class="align-middle">All Resources</b>
      </span>
      <div class="card-header-actions">
        <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddResource()">
          <i class="fa fa-plus mr-2"></i> Add Resource
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row text-center">
        <div class="col-sm-3" *ngFor="let resource of resources; let i = index">
          <app-resource-card [resource]='resource'></app-resource-card>
        </div>
      </div>
    </div>
  </div>
</div> -->

  <!--add resource modal-->
  <div bsModal #addResourceModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="addResourceModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Resource</h4>
          <button type="button" class="close" (click)="addResourceModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!--loading-->
          <div *ngIf="addResourceViewState == viewStates.loading" class="text-center">
            <div class="spinner-border text-ts" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <!--info-->
          <div *ngIf="addResourceViewState == viewStates.content">
            <!--info-->
            <div class="form-group">
              <label for="name">Title</label>
              <input type="text" id="resource-title" placeholder="Resource title" class="form-control"
                value={{newResource.title}} [(ngModel)]="newResource.title">
            </div>
            <div class="form-group">
              <label for="name">Description</label>
              <textarea type="text" id="resource-desc" placeholder="Resource description" class="form-control"
                value={{newResource.description}} [(ngModel)]="newResource.description"></textarea>
            </div>

            <!--checkboxes-->
            <div class="row text-center">
              <div class="col-md-6">
                <label class="switch switch-label switch-ts">
                  <input type="checkbox" class="switch-input" [checked]="newResource.published"
                    (change)="newResource.published = !newResource.published">
                  <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                </label>
                <div>Public</div>
              </div>
              <div class="col-md-6">
                <label class="switch switch-label switch-ts">
                  <input type="checkbox" class="switch-input" [checked]="newResource.free"
                    (change)="newResource.free = !newResource.free">
                  <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                </label>
                <div>Free Preview</div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="addResourceModal.hide()">Close</button>
          <button type="button" class="btn btn-dark btn-ts" (click)="addResource(newResource)">Save changes</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->