<div class="animated fadeIn">
  <h3 *ngIf="resource == null"></h3>
  <h3 *ngIf="resource != null">
    <img src="https://img.icons8.com/color/200/000000/open-book--v1.png" class="rounded me-2"
      style="object-fit: contain; width: 30px; height: 30px;">
    <span class="align-middle">{{resource.title}}</span>
  </h3>
  <!--loading-->
  <div *ngIf="viewState == viewStates.loading" class="text-center">
    <div class="spinner-border text-ts" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="resource != null && viewState == viewStates.content" class="row mt-4">
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <b>Resource Info</b>
        </div>
        <div class="card-body">
          <!--loading-->
          <div *ngIf="infoViewState == viewStates.loading" class="text-center">
            <div class="spinner-border text-ts" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <!--content-->
          <div *ngIf="infoViewState == viewStates.content">
            <!--image-->
            <div class="text-center">
              <div class="form-group">
                <input type="file" style="display: none;" id="resource-image" accept="image/*"
                  (change)="handleFileInput($event)" #resourceImage>
              </div>
              <img class="rounded" *ngIf="resource.coverImageUrl != null" src="{{resource.coverImageUrl}}"
                style="object-fit: cover; width: 150px; height: 150px; cursor: pointer;"
                (click)="didClickUploadImage()" />
              <img class="rounded" *ngIf="resource.coverImageUrl == null"
                src="https://img.icons8.com/color/200/000000/open-book--v1.png"
                style="object-fit: contain; width: 150px; height: 150px; cursor: pointer;"
                (click)="didClickUploadImage()" />
              <div><small>Click image to update</small></div>
            </div>

            <!--info-->
            <div class="form-group">
              <label for="name">Title</label>
              <input type="text" id="resource-title" placeholder="Resource title" class="form-control"
                value={{resource.title}} [(ngModel)]="resource.title">
            </div>
            <div class="form-group">
              <label for="name">Description</label>
              <textarea type="text" id="resource-desc" placeholder="Resource description" class="form-control"
                value={{resource.description}} [(ngModel)]="resource.description"></textarea>
            </div>

            <!--checkboxes-->
            <div class="row text-center">
              <div class="col-md-6">
                <label class="switch switch-label switch-ts">
                  <input type="checkbox" class="switch-input" [checked]="resource.published"
                    (change)="resource.published = !resource.published">
                  <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                </label>
                <div>Public</div>
              </div>
              <div class="col-md-6">
                <label class="switch switch-label switch-ts">
                  <input type="checkbox" class="switch-input" [checked]="resource.free"
                    (change)="resource.free = !resource.free">
                  <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
                </label>
                <div>Free Preview</div>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-pill btn-dark btn-ts w-100 mb-2 mt-2" (click)="saveResource()">
                <i class="fa fa-save me-2"></i>
                <span>Save</span>
              </button>

              <!--updated-->
              <div *ngIf="resource.updatedTimestamp != null" class="small mt-2 ms-2">
                <span>Updated </span>
                <span>{{moment(resource.updatedTimestamp).format('ll')}}</span>
              </div>

              <hr />

              <div class="text-danger"><b>Danger Zone</b></div>
              <div><small>These are destructive operations. Proceed with care.</small></div>
              <button class="btn btn-pill btn-danger w-100 mb-2 mt-2" (click)="confirmDelete()">
                <i class="fa fa-trash me-2"></i>
                <span>Delete</span>
              </button>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-9">
      <c-card *ngIf="viewState == viewStates.content" class="mb-4">
        <c-card-body>
          <c-row>
            <c-col sm="7">
              <h3 class="card-title mb-0" id="traffic">
                <img src="https://img.icons8.com/color/200/000000/books.png" class="rounded me-2"
                  style="object-fit: contain; width: 30px; height: 30px;">
                <b>Sections</b>
              </h3>
            </c-col>
            <c-col sm="5">
              <div style="text-align: right;">
                <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddSection()">
                  <i class="fa fa-plus me-2"></i> Add Section
                </button>
              </div>
            </c-col>
          </c-row>

          <div *ngIf="sections != null" class="mt-4">
            <div class="card mb-2" *ngFor="let section of sections; let i = index">
              <app-section-list-item [resource]='resource' [section]='section'></app-section-list-item>
            </div>
            <div *ngIf="sections.length == 0" class="text-center"><img
                src="https://img.icons8.com/cute-clipart/100/000000/list.png">
              <h2>No Sections Found</h2>
              <p>This section does not appear to have any subsections. To create a subsection, press the "+ Add"
                button below.</p>
              <button type="button" class="btn-pill btn btn-dark btn-ts" data-toggle="modal"
                (click)="presentAddSection()">
                <b><i class="fa fa-plus me-2"></i>Add Section</b>
              </button>
            </div>
          </div>

        </c-card-body>
      </c-card>


      <!-- <div class="card">
        <div class="card-header bg-white">
          <span class="h3"><b>Sections</b></span>
          <div class="card-header-actions">
            <button class="btn btn-pill btn-dark btn-ts" data-toggle="modal" (click)="presentAddSection()">
              <i class="fa fa-plus me-2"></i> Add Section
            </button>
          </div>
        </div>
        <div class="card-body">
          <div *ngIf="sections != null">
            <div class="card mb-2" *ngFor="let section of sections; let i = index">
              <app-section-list-item [resource]='resource' [section]='section'></app-section-list-item>
            </div>
            <div *ngIf="sections.length == 0" class="text-center"><img
                src="https://img.icons8.com/cute-clipart/100/000000/list.png">
              <h2>No Sections Found</h2>
              <p>This section does not appear to have any subsections. To create a subsection, press the "+ Add"
                button below.</p>
              <button type="button" class="btn-pill btn btn-dark btn-ts" data-toggle="modal"
                (click)="presentAddSection()">
                <b><i class="fa fa-plus me-2"></i>Add Section</b>
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!--add section modal-->
<div bsModal #addSectionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="addSectionModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Section</h4>
        <button type="button" class="close" (click)="addSectionModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--loading-->
        <div *ngIf="addSectionViewState == viewStates.loading" class="text-center">
          <div class="spinner-border text-ts" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <!--info-->
        <div *ngIf="addSectionViewState == viewStates.content">
          <div class="form-group">
            <label for="name">Title</label>
            <input type="text" id="section-title" placeholder="Section title" class="form-control"
              value={{newSection.title}} [(ngModel)]="newSection.title">
          </div>

          <!--Reference-->
          <div class="form-group">
            <label for="name">Reference Type & ID</label>
            <input type="text" id="section-referenceId" placeholder="Section Reference ID" class="form-control"
              value={{newSection.referenceId}} [(ngModel)]="newSection.referenceId">
          </div>

          <div class="form-group">
            <label for="name">Range</label>
            <input type="text" id="section-title" placeholder="Section title" class="form-control"
              value={{newSection.sectionRange}} [(ngModel)]="newSection.sectionRange">
          </div>

          <!--checkboxes-->
          <div class="row text-center">
            <div class="col-md-6">
              <label class="switch switch-label switch-ts">
                <input type="checkbox" class="switch-input" [checked]="newSection.published"
                  (change)="newSection.published = !newSection.published">
                <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
              </label>
              <div>Public</div>
            </div>
            <div class="col-md-6">
              <label class="switch switch-label switch-ts">
                <input type="checkbox" class="switch-input" [checked]="newSection.free"
                  (change)="newSection.free = !newSection.free">
                <span class="switch-slider" data-checked="&#x2713;" data-unchecked="&#x2715;"></span>
              </label>
              <div>Free Preview</div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="addSectionModal.hide()">Close</button>
        <button type="button" class="btn btn-dark btn-ts" (click)="addSection(newSection)">Save changes</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->