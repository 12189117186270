<c-card>
  <a routerLink="/resources/{{resource.id}}" style="overflow: hidden;">
    <img cCardImg="top" *ngIf="resource.coverImageUrl != null" src="{{resource.coverImageUrl}}"
    style="object-fit: cover; max-height: 200px; height: 200px; width: 100%;" />
  <img cCardImg="top" *ngIf="resource.coverImageUrl == null" src="https://img.icons8.com/color/200/000000/open-book--v1.png"
    style="object-fit: contain; max-height: 200px; height: 200px; width: 100%;" />
  </a>
  <c-card-body>
    <a routerLink="/resources/{{resource.id}}" style="overflow: hidden;">
      <h5 cCardTitle>{{resource.title}}</h5>
    </a>
    
    <div cCardText>
      <div>
        <c-badge *ngIf="resource.published" color="primary">PUBLIC</c-badge>
        <c-badge *ngIf="resource.free" color="success">FREE PREVIEW</c-badge>
      </div>
      <div *ngIf="resource.updatedTimestamp != null" class="small mt-2 ms-2">
        <span>Updated </span>
        <span>{{moment(resource.updatedTimestamp).format('ll')}}</span>
      </div>
    </div>
  </c-card-body>
</c-card>